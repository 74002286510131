import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            mapDashboard: null,
            mapDashboardStatus: null,
        }
    },
    mutations: {
        setMapDashboard(state, payload) {
            state.mapDashboard = payload
        },
        setMapDashboardStatus(state, payload) {
            state.mapDashboardStatus = payload
        },
    },
    actions: {
        setMapDashboard({ commit }, params) {
            commit('setMapDashboard', params)
        },
        setMapDashboardStatus({ commit }, params) {
            commit('setMapDashboardStatus', params)
        },
        async getMapDashboard({ commit, state }, params) {
            return await Api().get(
                `map-dashboards${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postMapDashboard({ commit, state }, params) {
            return await Api().post(
                `/map-dashboards`, params
            ).then((res) => {
                return res.data
            })
        },
        async editMapDashboard({ commit, state }, params) {
            return await Api().put(
                `/map-dashboards/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getMapDashboard(state) {
            return state.mapDashboard;
        },
        getMapDashboardStatus(state) {
            return state.mapDashboardStatus;
        },
    }
}
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            dashboardAccess: null,
            dashboardAccessStatus: null,
        }
    },
    mutations: {
        setDashboardAccess(state, payload) {
            state.dashboardAccess = payload
        },
        setDashboardAccessStatus(state, payload) {
            state.dashboardAccessStatus = payload
        },
    },
    actions: {
        setDashboardAccess({ commit }, params) {
            commit('setDashboardAccess', params)
        },
        setDashboardAccessStatus({ commit }, params) {
            commit('setDashboardAccessStatus', params)
        },
        async getDashboardAccess({ commit, state }, params) {
            return await Api().get(
                `dashboard-access${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postDashboardAccess({ commit, state }, params) {
            return await Api().post(
                `/dashboard-access`, params
            ).then((res) => {
                return res.data
            })
        },
        async editDashboardAccess({ commit, state }, params) {
            return await Api().put(
                `/dashboard-access/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getDashboardAccess(state) {
            return state.dashboardAccess;
        },
        getDashboardAccessStatus(state) {
            return state.dashboardAccessStatus;
        },
    }
}
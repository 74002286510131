<template>
  <div>
    <h2 class="content-block">
      <span v-if="title">{{ title }}</span>
    </h2>

    <filter-table-unit-bisnis
      v-if="role == 'admin' || role == 'suAdmin'"
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
    ></filter-table-unit-bisnis>

    <div
      class="content-block dx-card responsive-paddings"
      style="justify-content: center; display: flex"
    >
      <DxRadioGroup
        :items="requestReasonOptions"
        value-expr="key"
        display-expr="name"
        v-model:value="valueRequestReason"
        layout="horizontal"
        @valueChanged="changeRequestReason()"
      >
      </DxRadioGroup>
    </div>

    <br />

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          v-if="role == 'head' && condition == null"
          hint="approve"
          icon="fa fa-check-to-slot"
          @click="popupHeadAction"
        />
        <DxButton
          v-if="role == 'admin' && condition == null"
          hint="approve"
          icon="fa fa-check-to-slot"
          @click="popupAdminAction"
        />
        <DxButton
          hint="detail"
          icon="fa fa-book-open-reader"
          @click="viewDetail"
        />
        <DxButton
          v-if="role == 'admin' && condition == 'history'"
          hint="direct"
          icon="fa fa-paper-plane"
          @click="direct"
        />
        <DxButton
          v-if="(role == 'user' || role == 'suAdmin') && condition == null"
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
        />
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="role == 'suAdmin'"
        />
      </dx-column>
      <dx-column data-field="request_reason" caption="Keperluan" />
      <dx-column
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />
      <dx-column data-field="doc_type" caption="Tipe Dokumen" />
      <dx-column data-field="doc_number" caption="Nomor Dokumen" />
      <dx-column data-field="doc_name" caption="Nama Dokumen" />
      <dx-column
        v-if="condition == null && role == 'admin'"
        data-field="head_signed"
        caption="Disetujui Kepala Department"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="request_status"
        caption="Status"
        cell-template="grid-cell"
        width="100"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            approve: data.value == 'approve',
            reject: data.value == 'reject',
            pending: data.value == 'pending',
          }"
        >
          {{ data.value }}
        </div>
      </template>

      <dx-column
        v-if="condition == 'history'"
        data-field="verificator_date"
        caption="Tanggal Verifikasi"
        :customize-text="dateColumn"
      />
      <dx-column
        v-if="condition == 'history' && role == 'admin'"
        data-field="sla"
        caption="Sla"
      />
      <!-- <dx-column data-field="purpose" caption="Tujuan" />
      <dx-column data-field="approval_reason" caption="Alasan" /> -->
      <dx-column
        data-field="attachment"
        caption="Lampiran"
        cell-template="grid-file"
      />
      <template #grid-file="{ data }">
        <div v-if="data.value">
          <a :href="data.value" class="dx-icon-file link-icon"></a>
        </div>
        <span v-else>-</span>
      </template>
      <!-- <dx-column
        data-field="revision_points"
        caption="Poin Revisi"
        :cell-template="cellTemplate"
      /> -->
    </dx-data-grid>

    <!-- popup verificator -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupHead"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan Permintaan Dokumen sudah dicek!</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Approve"
              icon="check"
              type="success"
              @click="headVerif(true)"
            />
            <DxButtonx
              class="customBtn"
              text="Reject"
              icon="close"
              type="danger"
              @click="headVerif('reject')"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup admin -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupAdmin"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan Permintaan Dokumen sudah dicek!</b>
          <DxTextArea
            class="custom-form"
            label="Alasan"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="reason"
          >
          </DxTextArea>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Approve"
              icon="check"
              type="success"
              @click="adminVerif('approve')"
            />
            <DxButtonx
              class="customBtn"
              text="Reject"
              icon="close"
              type="danger"
              @click="adminVerif('reject')"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <DxPopup
      width="60%"
      height="80%"
      :show-title="true"
      title="Detail Request For Document (RFD)"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Keperluan</div>
                  <div class="dx-field-value">
                    : {{ detailData.request_reason }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Dibuat</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.applicant_signed }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Dibuat Oleh</div>
                  <div class="dx-field-value">
                    : {{ detailData.applicant_signature }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Verifikasi Head</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.head_signed }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Mengetahui</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.head_signature }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tanggal Verifikasi Admin</div>
                  <div class="dx-field-value">
                    : {{ dateColumn({ value: detailData.verificator_date }) }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">DiTerima Oleh</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.verificator_signature }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">SLA</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.sla }}
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Status</div>
                  <div class="dx-field-value">
                    :
                    <span
                      :class="{
                        green: detailData.request_status == 'approve',
                        red: detailData.request_status == 'reject',
                        yellow: detailData.request_status == 'pending',
                      }"
                      >{{ detailData.request_status }}</span
                    >
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Keterangan Status</div>
                  <div class="dx-field-value">
                    :
                    {{ detailData.approval_reason }}
                  </div>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Nomor Dokumen</div>
                  <div class="dx-field-value">
                    : {{ detailData.doc_number }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Nama Dokumen</div>
                  <div class="dx-field-value">: {{ detailData.doc_name }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tipe Dokumen</div>
                  <div class="dx-field-value">: {{ detailData.doc_type }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tujuan</div>
                  <div class="dx-field-value">: {{ detailData.purpose }}</div>
                </div>
              </div>

              <div
                class="dx-card responsive-paddings"
                v-if="detailData.revision_points != null"
              >
                <div class="dx-field">
                  <div>
                    <b style="text-align: center">Point Revisi :</b>
                  </div>
                </div>
                <div class="dx-field">
                  <div
                    style="padding: 0px; margin: 0px"
                    v-html="detailData.revision_points"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
    
    <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
} from "devextreme-vue/data-grid";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxBox } from "devextreme-vue/box";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";
import DxRadioGroup from "devextreme-vue/radio-group";
import { DxScrollView } from "devextreme-vue/scroll-view";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || `updated_at`,
            sort: sort || "desc",
            search: filterValue,
            department: vthis.department || "",
            request_reason: vthis.valueRequestReason || "",
          });

          return await datax;
        },
      }),
    };

    return {
      dataSource: dataSource,
      gridRef: "rfd-table",
      unitBisnis: "",
      department: "",
      valueRequestReason: "",
      requestReasonOptions: [
        { name: "All", key: "" },
        { name: "Permintaan Dokumen", key: "Permintaan Dokumen" },
        { name: "Perubahan Dokumen Baru", key: "Perubahan Dokumen Baru" },
        { name: "Buat Dokumen Baru", key: "Buat Dokumen Baru" },
      ],
      request_status: this.requestStatus,
      history: this.history,
      pageSizes: [5, 10, 15],
      popupHead: false,
      popupAdmin: false,
      reqDocHead: {},
      reqDocAdmin: {},
      access: {},
      reason: "",
      title: "",
      popupDelete: false,
      deleteReqDoc: {},
      popupDetail: false,
      detailData: {},
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("requestDocument", [
      "setRequestDocumentStatus",
      "setRequestDocument",
    ]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  props: {
    role: {
      type: String,
    },
    requestStatus: {
      type: String,
    },
    inverse: {
      type: String,
    },
    notNull: {
      type: String,
    },
    whereNull: {
      type: String,
    },
    condition: {
      type: String,
      default: null,
    },
    applicant: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["title"],
  methods: {
    viewDetail(params) {
      this.popupDetail = true;

      this.detailData = params.row.data;
    },
    cellTemplate(container, options) {
      container.innerHTML = options.data.revision_points;
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    confirmDestroy() {
      this.$store
        .dispatch("requestDocument/destroyRequestDocument", this.deleteReqDoc)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteReqDoc = params.row.data;
    },
    async headVerif(params) {
      const now = new Date();
      if (params == "reject") {
        this.reqDocHead.request_status = params;
      } else {
        this.reqDocHead.head_signed = now;
      }

      this.reqDocHead.received_date = now;
      this.reqDocHead.head_signature = this.getUserDetail.full_name;
      this.reqDocHead._method = "PUT";

      this.popupHead = false;
      this.updateReqDoc(this.reqDocHead);
    },
    popupHeadAction(params) {
      this.reqDocHead = params.row.data;
      this.popupHead = true;
    },
    async direct(params) {
      const row = params.row.data;
      if (row.request_status == "approve") {
        if (row.request_reason == "Buat Dokumen Baru") {
          router.push("/admin/document/add");
          this.$store.dispatch("document/setDocumentStatus", "Redirect");
          this.$store.dispatch("requestDocument/setRequestDocument", row);
        } else if (row.request_reason == "Perubahan Dokumen Baru") {
          const document = await this.$store.dispatch("document/getDocument", {
            id: row.document_id,
          });
          this.$store.dispatch("document/setDocument", document.data);
          this.$store.dispatch("document/setDocumentStatus", "Edit");
          router.push("/admin/document/add");
        } else {
          notify("Permintaan Dokumen berhasil !", "success", 2000);
        }
        return;
      }
      notify("Status reject !", "error", 2000);
    },
    async adminVerif(params) {
      this.reqDocAdmin.request_status = params;
      this.reqDocAdmin.approval_reason = this.reason;
      const now = new Date();

      this.reqDocAdmin.verificator_id = this.getUserDetail.user_id;
      this.reqDocAdmin.verificator_signature = this.getUserDetail.full_name;
      this.reqDocAdmin.verificator_date = now;
      this.reqDocAdmin._method = "PUT";

      const sla = new Date(
        new Date(this.reqDocAdmin.received_date).getTime() +
          15 * 24 * 60 * 60 * 1000
      );

      if (new Date().getTime() <= sla.getTime()) {
        this.reqDocAdmin.sla = "on time";
      } else {
        this.reqDocAdmin.sla = "delay";
      }
      this.popupAdmin = false;

      this.updateReqDoc(this.reqDocAdmin);
    },
    popupAdminAction(params) {
      this.reqDocAdmin = params.row.data;
      this.popupAdmin = true;
    },
    updateReqDoc(params) {
      const cThis = this;
      this.$store
        .dispatch("requestDocument/updateRequestDocument", params)
        .then((res) => {
          const now = new Date();
          const data = res.data.data;
          if (
            data.request_status == "approve" &&
            data.request_reason == "Permintaan Dokumen"
          ) {
            this.access.document_id = data.document_id;
            this.access.document_name = data.doc_number;
            this.access.user_id = data.applicant_id;
            this.access.fullname = data.applicant_signature;
            this.access.expired_date = new Date(now.setDate(now.getDate() + 7));
            this.postDocAccessRight(this.access);
          }
          notify("Verifikasi Berhasil", "success", 2000);
          // belum selesai
          cThis.dataGrid.refresh();
        });
    },
    async postDocAccessRight(params) {
      await this.$store.dispatch("docAccessRight/postDocAccess", params);
    },
    async edit(params) {
      this.$store.dispatch("requestDocument/setRequestDocumentStatus", "Edit");
      this.$store.dispatch(
        "requestDocument/setRequestDocument",
        params.row.data
      );
      if (this.role == "suAdmin") {
        router.push(`/super-admin/req-doc/form`);
      } else {
        router.push(`/user/request-document/add`);
      }
    },
    // pointColumn(cellInfo) {
    //   if (cellInfo.value == null) {
    //     return "-";
    //   }
    //   return cellInfo.value;
    // },
    dateColumn(cellInfo) {
      if (!cellInfo.value) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    changeRequestReason() {
      this.dataGrid.refresh();
    },
    getCompany(value) {
      this.unitBisnis = value;
      this.dataGrid.refresh();
    },
    async getDepartment(value) {
      this.department = value;

      const text = `?department=${value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      if (value) {
        this.title =
          // "Daftar Permintaan Dokumen " + depart.data[0].department_name;
          this.$emit("title", depart.data[0].department_name);
      } else {
        // this.title = "";
        this.$emit("title", value);
      }

      this.dataGrid.refresh();
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (
        this.condition == null &&
        (vThis.role == "user" || vThis.role == "suAdmin")
      ) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.$store.dispatch(
                "requestDocument/setRequestDocumentStatus",
                "Add"
              );
              if (vThis.role == "user") {
                router.push("/user/request-document/add");
              } else {
                router.push("/super-admin/req-doc/form");
              }
            },
          },
        });
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      const detail = await this.$store.getters["authentication/getUserDetail"];
      if (this.role == "user" && this.condition == null) {
        params.request_status = "pending";
        params.applicant = detail.user_id;
        this.title = "Daftar Permintaan Dokumen " + detail.full_name;
      } else if (this.role == "user" && this.condition == "history") {
        params.department = detail.department_id;
        params.history = "pending";
        this.title = "Riwayat Permintaan Dokumen " + detail.department_name;
      } else if (this.role == "head") {
        params.where_null = "head_signed";
        params.request_status = "pending";
        params.department = detail.department_id;
        this.title = "Menunggu Persetujuan Head " + detail.department_name;
      } else if (this.role == "admin" && this.condition == "history") {
        params.history = "pending";
        params.not_null = "head_signed";
      } else if (this.role == "admin" && this.condition == null) {
        params.request_status = "pending";
        params.not_null = "head_signed";
      } else if (this.role == "suAdmin" && this.condition == null) {
        params.request_status = "pending";
      } else if (this.role == "suAdmin" && this.condition == "history") {
        params.history = "pending";
      } else {
        false;
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "requestDocument/getRequestDocuments",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxBox,
    DxSelectBox,
    FilterTableUnitBisnis,
    DxPopup,
    DxButtonx,
    DxRadioGroup,
    DxScrollView,
  },
};
</script>

<style scoped>
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}

.approve {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.reject {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.pending {
  background-color: yellow;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.green {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.red {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.yellow {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.dx-field-label {
  white-space: normal;
}
</style>